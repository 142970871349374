import { GASnowplowJS } from '@generalassembly/ga-snowplow-js'

window.cookieConsent.addCallback('performance', () => {
  const gaSnowplow = new GASnowplowJS(window.snowplowConfig)

  gaSnowplow.pageViewCustomContexts = () => [{
    schema: 'iglu:co.ga/page_context/jsonschema/1-0-0',
    data: {
      locale: window.snowplowPageContext.locale,
    },
  }]

  gaSnowplow.init()

  gaSnowplow.setContext('page', {
    schema: 'iglu:co.ga/page_context/jsonschema/1-0-0',
    data: {
      locale: window.snowplowPageContext.locale,
    },
  })
})
